import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { useFormik } from "formik";
import * as Yup from "yup";
import jsonCountries from "./assets/countries.json";

const CheckoutFormSchema = Yup.object().shape({
  fname: Yup.string().required("Please enter your first name."),
  lname: Yup.string().required("Please enter your last name."),
  email: Yup.string().email().required("Please enter your email address."),
  phone: Yup.number().required("Please enter your phone number."),
  country: Yup.string().required("Please select country."),
  street: Yup.string().required("Please enter your exact street address."),
  city: Yup.string().required("Please enter your city/district."),
  copies: Yup.number().required("Please enter number of copies."),
});

const CheckOutFormPremiumPlus = () => {
  const formik = useFormik({
    initialValues: {
      fname: "",
      lname: "",
      email: "",
      phone: "",
      street: "",
      city: "",
      country: "",
      copies: 1,
      coupon: "",
      gift: "",
      additionalInfo: "",
      cost: { ugx: 195000, usd: 54.99 },
    },
    onSubmit: (values) => {
      handleFlutterPayment({
        callback: (response) => {
          // Do something
          console.log(response);

          // close fw payment modal
          closePaymentModal();
        },
        onClose: () => {
          // Do something
          console.log(values);
        },
      });
    },
    validationSchema: CheckoutFormSchema,
  });

  const handleSchemaChange = (e, setFieldValue) => {
    // ... the rest of your implementation
    if (e.target.value.trim()) {
      if (parseInt(e.target.value) > 0) {
        setFieldValue("copies", e.target.value);
        formik.values.cost = {
          ugx: 195000 * parseInt(e.target.value),
          usd: 54.99 * parseInt(e.target.value),
        };
      } else {
        setFieldValue("copies", "");
        formik.values.cost = {
          ugx: 195000 * 1,
          usd: 54.99 * 1,
        };
      }
    } else {
      setFieldValue("copies", "");
      formik.values.cost = {
        ugx: 195000 * 1,
        usd: 54.99 * 1,
      };
    }
  };

  const fwConfig = {
    public_key: "FLWPUBK-4cf28291da5f794b8960fa3658e531d2-X",
    tx_ref: Date.now(), // Should be unique for every transaction
    amount: formik.values.cost.ugx,
    currency: "UGX",
    payment_options: "card, mobilemoneyuganda, account, banktransfer, ussd",
    customer: {
      email: formik.values.email,
      phone_number: formik.values.phone,
      name: `${formik.values.fname} ${formik.values.lname}`,
    },
    meta: formik.values,
    redirect_url: "www.dadsonbook.com/thank-you",
    customizations: {
      // Customize as you wish
      title: "Payment for MALIDAD BOOKS PREMIUM PLUS BUNDLE",
      description: "Payment for MALIDAD BOOKS PREMIUM PLUS BUNDLE",
      logo: "https://res.cloudinary.com/malidad/image/upload/v1678941823/PremiumPlus_cyhc43.jpg",
    },
  };

  const handleFlutterPayment = useFlutterwave(fwConfig);

  return (
    <div>
      <form className="checkout-form" onSubmit={formik.handleSubmit}>
        <ul className="form-items-container">
          <li className="form-item no-margin-top">
            <label className="form-field-label">
              Your Name <span className="required">*</span>
            </label>
            <div className="form-field-container">
              <div className="form-fields-container flex">
                <div className="side-field width-48">
                  <label className="rich-small" htmlFor="fname">
                    First Name
                  </label>
                  <div className="form-field-container">
                    <input
                      type="text"
                      className="form-input form-input-text large-input input-name"
                      // required
                      aria-required="true"
                      aria-invalid="false"
                      id="fname"
                      name="fname"
                      value={formik.values.fname}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      maxLength={25}
                      placeholder="e.g John"
                    />
                    <span className="field-sample">E.g John</span>
                    {formik.errors.fname && formik.touched.fname ? (
                      <span className="form-field-feedback">
                        {formik.errors.fname}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="side-field width-48">
                  <label className="rich-small" htmlFor="lname">
                    Last Name
                  </label>
                  <div className="form-field-container">
                    <input
                      type="text"
                      className="form-input form-input-text large-input input-name"
                      // required
                      aria-required="true"
                      aria-invalid="false"
                      id="lname"
                      name="lname"
                      value={formik.values.lname}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      maxLength={25}
                      placeholder="e.g Doe"
                    />
                  </div>
                  <span className="field-sample">E.g Doe</span>
                  {formik.errors.lname && formik.touched.lname ? (
                    <span className="form-field-feedback">
                      {formik.errors.lname}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </li>
          <li className="form-item">
            <label className="form-field-label" htmlFor="email">
              Your email address <span className="required">*</span>:
            </label>
            <div className="form-field-container">
              <input
                type="email"
                className="form-input form-input-text large-input"
                // required
                aria-required="true"
                aria-invalid="false"
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                maxLength={40}
                placeholder="e.g johndoe@example.com"
                pattern="^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$"
              />
            </div>
            <span className="field-sample">E.g johndoe@example.com</span>
            {formik.errors.email && formik.touched.email ? (
              <span className="form-field-feedback">{formik.errors.email}</span>
            ) : null}
          </li>
          <li className="form-item">
            <label className="form-field-label" htmlFor="phone">
              Your Phone Number <span className="required">*</span>:
            </label>
            <div className="form-field-container">
              <input
                type="tel"
                className="form-input form-input-text large-input"
                // required
                aria-required="true"
                aria-invalid="false"
                id="phone"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                maxLength={40}
                placeholder="+256 781 234 567"
              />
            </div>
            <span className="field-sample">
              E.g +256 781 234 567 where +256 is country code
            </span>
            {formik.errors.phone && formik.touched.phone ? (
              formik.errors.phone === "Please enter your phone number." ? (
                <span className="form-field-feedback">
                  {formik.errors.phone}
                </span>
              ) : (
                <span className="form-field-feedback">
                  Only numbers are required
                </span>
              )
            ) : null}
          </li>
          <li className="form-item">
            <label className="form-field-label" htmlFor="street">
              Delivery Address <span className="required">*</span>:
            </label>
            <div>
              <label className="rich-small" htmlFor="street">
                Street Address
              </label>
              <div className="form-field-container">
                <input
                  type="text"
                  className="form-input form-input-text large-input"
                  // required
                  aria-required="true"
                  aria-invalid="false"
                  id="street"
                  name="street"
                  value={formik.values.street}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  maxLength={150}
                  placeholder="Market street, David Arcade, Floor 1, Room 2"
                />
                <span className="field-sample">
                  E.g Market street, David Arcade, Floor 1, Room 2
                </span>
                {formik.errors.street && formik.touched.street ? (
                  <span className="form-field-feedback">
                    {formik.errors.street}
                  </span>
                ) : null}
              </div>
              <div className="form-fields-container flex margin-top-10">
                <div className="side-field width-48">
                  <label className="rich-small" htmlFor="fname">
                    City/District
                  </label>
                  <div className="form-field-container">
                    <input
                      type="text"
                      className="form-input form-input-text large-input input-name"
                      // required
                      aria-required="true"
                      aria-invalid="false"
                      id="city"
                      name="city"
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      maxLength={50}
                      placeholder="Kampala"
                    />
                    <span className="field-sample">E.g Kampala</span>
                    {formik.errors.city && formik.touched.city ? (
                      <span className="form-field-feedback">
                        {formik.errors.city}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="side-field width-48">
                  <label className="rich-small" htmlFor="fname">
                    County
                  </label>
                  <div className="form-field-container">
                    <div className="custom-select-container">
                      <select
                        as="select"
                        // required
                        id="country"
                        name="country"
                        value={formik.values.country}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-input form-input-text input-select no-border-radius country-codes-select"
                      >
                        <option value="0">Select Country</option>
                        {jsonCountries.countries.map((country, index) => (
                          <option value={country.code} key={index}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {formik.errors.country && formik.touched.country ? (
                    <span className="form-field-feedback">
                      {formik.errors.country}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </li>
          <li className="form-item">
            <label className="form-field-label" htmlFor="copies">
              Number of Copies <span className="required">*</span>:{" "}
              <div className="total center rich-small margin-bottom-10">
                <p className="bold red">SUBTOTAL:</p>
                <p>
                  <span>
                    <span className="bold">
                      {formik.values.cost.ugx.toLocaleString("en-US", {
                        style: "currency",
                        currency: "UGX",
                      })}
                    </span>
                  </span>
                  <span className=" spaced-left-right-4">|</span>
                  <span>
                    USD:{" "}
                    <span className="bold">
                      {formik.values.cost.usd.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </span>
                  </span>
                </p>
              </div>
            </label>
            <div className="form-field-container">
              <input
                type="number"
                className="form-input form-input-text large-input"
                // required
                aria-required="true"
                aria-invalid="false"
                id="copies"
                name="copies"
                value={formik.values.copies}
                onChange={(e) => handleSchemaChange(e, formik.setFieldValue)}
                onBlur={formik.handleBlur}
                minLength={1}
                placeholder="E.g. 10"
              />
            </div>
            <span className="field-sample">E.g 10</span>
            {formik.errors.copies && formik.touched.copies ? (
              <span className="form-field-feedback">
                {formik.errors.copies}
              </span>
            ) : null}
          </li>
          {/*
          <li className="form-item">
            <label className="form-field-label" htmlFor="coupon">
              Coupon Code :
            </label>
            <div className="form-field-container">
              <input
                type="text"
                className="form-input form-input-text large-input"
                aria-required="false"
                aria-invalid="false"
                id="coupon"
                name="coupon"
                value={formik.values.coupon}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                maxLength={40}
                placeholder="MALIDAD"
              />
            </div>
            <span className="field-sample">E.g MALIDAD</span>
            {formik.errors.coupon && formik.touched.coupon ? (
              <span className="form-field-feedback">
                {formik.errors.coupon}
              </span>
            ) : null}
          </li>
          */}
          <li className="form-item">
            <label className="form-field-label" htmlFor="coupon">
              Are you gifting?
            </label>
            <div className="form-field-container">
              <div className="custom-input radio">
                <input
                  type="radio"
                  className="form-input-radio"
                  id="giftYes"
                  name="gift"
                  value="Yes"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <label className="form-field-label" htmlFor="giftYes">
                  Yes
                </label>
              </div>
              <div className="custom-input radio">
                <input
                  type="radio"
                  className="form-input-radio"
                  id="giftNo"
                  name="gift"
                  value="No"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <label className="form-field-label" htmlFor="giftNo">
                  No
                </label>
              </div>
            </div>
            {formik.errors.gift && formik.touched.gift ? (
              <span className="form-field-feedback">{formik.errors.gift}</span>
            ) : null}
          </li>
          <li className="form-item no-margin-bottom">
            <label className="form-field-label " htmlFor="additionalInfo">
              Additional Information
            </label>
            <div className="form-field-container no-line-height">
              <textarea
                component="textarea"
                id="additionalInfo"
                name="additionalInfo"
                value={formik.values.additionalInfo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="input form-input-text form-input-area textarea-100"
                placeholder="Please type here..."
              />
            </div>
            <span className="field-sample">
              Special note about your order or delivery
            </span>
            {formik.errors.additionalInfo && formik.touched.additionalInfo ? (
              <span className="form-field-feedback">
                {formik.errors.additionalInfo}
              </span>
            ) : null}
          </li>
          <li className="form-item">
            <div className="total center rich-small margin-bottom-10">
              <p className="bold red">SUBTOTAL:</p>
              <p>
                <span>
                  <span className="bold">
                    {formik.values.cost.ugx.toLocaleString("en-US", {
                      style: "currency",
                      currency: "UGX",
                    })}
                  </span>
                </span>
                <span className=" spaced-left-right-4">|</span>
                <span>
                  USD:{" "}
                  <span className="bold">
                    {formik.values.cost.usd.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </span>
                </span>
              </p>
            </div>
            <div className="button-container center">
              <button
                type="submit"
                className="button light-yellow-bg bold uppercase width-100"
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting ? "Processing..." : "Pay Now"}
              </button>
            </div>
            <p className="rich-small margin-top-10 block center capitalize underline">
              ***SubTotal before transaction and delivery fees***
            </p>
            <p className="rich-small margin-top-10 block center capitalize underline">
              ***Payment Processing is handled and secured by{" "}
              <a
                href="https://flutterwave.com/us/"
                target="_blank"
                rel="noopener noreferrer"
              >
                flutterwave
              </a>
              ***
            </p>
          </li>
        </ul>
      </form>
    </div>
  );
};

export default CheckOutFormPremiumPlus;
