import React from "react";
import PremiumBundle from "../reusables/PremiumBundle";
import PremiumPlusBundle from "../reusables/PremiumPlusBundle";
import PremiumDaughterBundle from "../reusables/PremiumDaughterBundle";
import PremiumSonBundle from "../reusables/PremiumSonBundle";

const BookBundles = () => {
  return (
    <section className=" section-spacing-80 dark-bg" id="bundle">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-head center width-300 margin-auto">
              <h3 className="section-title title light-yellow">
                Buy in Bundles &amp; Save (10,000 Ugx | $2.5 USD)
              </h3>
            </div>
            <div className="section-body section-spacing-50 no-padding-bottom ">
              <div className="bundles flex">
                <PremiumBundle />
                <PremiumSonBundle />
                <PremiumPlusBundle />

                {/*<PremiumDaughterBundle />*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BookBundles;
