import React from "react";
import { Slide } from "react-reveal";
import brad from "../../assets/images/reviewers/brad.png";
import malbie from "../../assets/images/reviewers/malbie.jpg";
import ibrah from "../../assets/images/reviewers/ibrah.jpg";
import sharaf from "../../assets/images/reviewers/shara.jpg";
import ReviewerCard from "../reusables/ReviewerCard";

const Reviews = () => {
  return (
    <section className=" section-spacing-100" id="reviews">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <Slide top>
              <div className="section-head center width-300 margin-auto">
                <h3 className="section-title title">
                  <span className=" light-yellow">Dad Son Book</span> Reviews
                </h3>
              </div>
            </Slide>
            <div className="section-body section-spacing-50">
              <div className="reviews-cards-container relative carousel">
                <div className="background-bar dark-bg"></div>
                <div className="margin-auto">
                  <div className="reviews-cards-inner slider-items-container center-slide-arrows">
                    <ReviewerCard
                      revImg={brad}
                      revName="Brad Odilo"
                      revBio="Author, Father, Systems Thinker"
                      brief="Dad Son book is exactly the book I will be gifting my Son. It really is a masculine masterpiece."
                    >
                      <p>
                        I know exactly what book I will be gifting my Son.
                        Having had the privilege to savor each lesson penned
                        down in this collection has awarded me useful handles to
                        gift my Son. Each lesson contained in the Dad Son book
                        is profitable and honest.
                      </p>
                      <p className=" margin-top-10">
                        It really is a masculine masterpiece. Every lesson is
                        communicated from a place of authenticity and realness.
                      </p>
                    </ReviewerCard>
                    <ReviewerCard
                      revImg={malbie}
                      revName="Bruno Mukisa"
                      revBio="Writer, Architect and Poet"
                      brief="The Dad Son book is a 'Trove of Invaluable Wisdom' that a father should equip his Son with."
                    >
                      <p>
                        The Dad Son book is a trove of invaluable wisdom that a
                        father should equip his Son with. I am confident that if
                        consciously considered, a copy of this book is one of
                        the best things a father can leave their son with as an
                        inheritance because if one lesson learnt can transform
                        your entire life, imagine the whole world of a
                        difference that 200 lessons of the same kind can bring
                        forth!
                      </p>
                    </ReviewerCard>
                    <ReviewerCard
                      revImg={sharaf}
                      revName="Abdallah Sharaf"
                      revBio="Manager MRUL, Father"
                      brief="The Dad Son book is a syllabus for the best education institution, home. Very informative, guiding and educative to generations."
                    >
                      <p>
                        The academic education system has a syllabus to guide
                        teachers on how and what to teach students. But, we all
                        know the best education is from home. And home has not
                        had a well scripted syllabus until this book. This Dad
                        Son book, is a syllabus for the best education
                        institution, home. Very informative, guiding and
                        educative to generations.
                      </p>
                    </ReviewerCard>
                    <ReviewerCard
                      revImg={ibrah}
                      revName="Atuhairwe Ibrahim"
                      revBio="Son, Student leader"
                      brief="The Dad Son book is easy to read and self-explanatory."
                    >
                      <p>
                        The world is drastically changing hence it is very easy
                        for someone to lose oneself, tradition and morals. The
                        Dad Son book is a must have guide for every father of
                        the current generation. It is easy to read and
                        self-explanatory. Indeed, the ultimate guidance every
                        dad must incorporate in the life of his son not only to
                        make a responsible man out of him but also a responsible
                        citizen.
                      </p>
                    </ReviewerCard>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reviews;
