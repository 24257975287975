import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { heroReviewsCarousel } from "../reusables/heroReviewsCarousel";
import star from "../../assets/images/icons/star.svg";
import right from "../../assets/images/icons/right.svg";
import cover from "../../assets/images/content/cover.png";
import { Slide } from "react-reveal";
import wft from "../../assets/images/content/wft.png";
import ReviewSlideCard from "../reusables/ReviewSlideCard";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import visa from "../../assets/images/pay/visa.svg";
import airtel from "../../assets/images/pay/airtel.svg";
import mtn from "../../assets/images/pay/mtn.svg";
import mastercard from "../../assets/images/pay/mastercard.svg";

const HomeHero = () => {
  return (
    <section>
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="hero-contents-container flex">
              <div className="text-side">
                <div className="hero-rating flex">
                  <img src={star} alt="Star" className="icon" />
                  <p className="bold">4.9</p>
                  <p className="bold dark">Reader Reviews</p>
                </div>
                <div className="text-side-body center">
                  <h2 className="hero-title">
                    Dad <br />
                    Son Book
                  </h2>
                  <p className="hero-subtitle capitalize">
                    <span className="bold">200 Life Lessons</span> every{" "}
                    <span className="bold">Father</span> must{" "}
                    <span className="bold">Teach</span> his{" "}
                    <span className="bold">Son</span>
                  </p>
                </div>
                <div className="text-side-cta flex">
                  <div className="main-cta modal-container">
                    <p className="left rich-small margin-bottom-10 hero-offer">
                      <span className="block bold uppercase red">
                        RETAIL PRICE:
                      </span>

                      <span className=" block margin-top-10">
                        UGX: <span className=" bold">54,999</span> | USD{" "}
                        <span className=" bold">$15.99</span>
                      </span>
                    </p>
                    <div className="buy-cta">
                      <a
                        href="/buy"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button flex light-yellow-button bold uppercase modal-trigger"
                      >
                        <span className="right-push-4">Order Your Copy</span>
                        <img src={right} alt="Right Arrow" className="icon" />
                      </a>
                      <div className="accepted-payments">
                        <p className="rich-small uppercase bold">PAY WITH</p>
                        <div className="flex">
                          <img src={visa} alt="VISA" />
                          <img src={mastercard} alt="MASTERCARD" />
                          <img src={mtn} alt="MTN MOMO" />
                          <img src={airtel} alt="AIRTEL MOMO" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sub-cta">
                    <a href="#reviews" className="button uppercase bold">
                      See Reviews
                    </a>
                  </div>
                </div>
              </div>
              <Slide top>
                <div className="image-side">
                  <div className="background"></div>
                  <img src={cover} alt="Dad Son Book" className="cover" />
                </div>
              </Slide>
            </div>
            <div className="hero-media">
              <div className="carousel">
                <div className="slider-items-container hero-review-slide">
                  <Slider {...heroReviewsCarousel}>
                    <ReviewSlideCard
                      revName="Bruno Mukisa"
                      revText="The Dad Son book is a 'Trove of Invaluable Wisdom' that a father should equip his Son with."
                    />
                    <ReviewSlideCard
                      revName="Brad Odilo"
                      revText="Dad Son book is exactly the book I will be gifting my Son. It really is a masculine masterpiece."
                    />
                    <ReviewSlideCard
                      revName="Abdallah Sharaf"
                      revText="The Dad Son book is a syllabus for the best education institution, home. Very informative, guiding and educative to generations."
                    />
                    <ReviewSlideCard
                      revName="Atuhairwe Ibrahiim"
                      revText="The Dad Son book is easy to read and self-explanatory."
                    />
                  </Slider>
                </div>
              </div>
              <div className="section-video">
                <LiteYouTubeEmbed
                  id="YQxn00CSP2o"
                  playlist={false}
                  title="Father shares wisdom to his son and his fiance"
                />
                <div className="video-title">
                  <p className="center rich-small">
                    Father shares wisdom to his son and his fiance
                  </p>
                </div>
              </div>
            </div>

            <div className="section-footer center width-300 margin-auto">
              <div className="wft section-spacing-40 no-padding-bottom">
                <img
                  src={wft}
                  alt="Word For Today Publications"
                  className="wft-img"
                />
              </div>
              <p className="rich-small center uppercase margin-bottom-10">
                books by malidad.com are published, marketed and distributed by
                word for today publications limited{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
