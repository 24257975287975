import React, { useState } from "react";
import { Slide } from "react-reveal";
import cover from "../../assets/images/content/ds-piled.png";
import cover2 from "../../assets/images/content/cover.png";
import BuyModalContents from "../reusables/BuyModalContents";
import Modal from "../reusables/Modal";

const AboutBook = () => {
  const [show, setShow] = useState(false);
  return (
    <section className="section-spacing-100" id="about">
      <div className="about-inner">
        <div className="container">
          <div className="large">
            <div className="section-content-container">
              <Slide top>
                <div className="section-head center width-300 margin-auto">
                  <h3 className="section-title title white">
                    About <br></br>
                    <span>Dad Son Book</span>
                  </h3>
                </div>
              </Slide>
              <div className="section-body section-spacing-40">
                <div className="about-container flex">
                  <div className="about-text-side">
                    <h4 className=" italic">
                      <q>A boy is born. A man is built</q> - MALIDAD.COM
                    </h4>
                    <p className="margin-top-10 ">
                      There is a bigger calling and higher responsibility for a
                      Father to his children especially the Son, beyond ensuring
                      that they don't walk or sleep on an empty stomach. As a
                      Father, you are the best man from whom your Son learns how
                      to think, act, behave, walk, live and deal with
                      situations. You are your Son’s biggest mentor in his life.
                    </p>
                    <p className="margin-top-10 ">
                      Take a moment and ask yourself: What do you really want
                      for your Son? What qualities do you hope he develops and
                      takes into his adult life? Now, think about the percentage
                      of your time you spend intentionally developing those
                      qualities in your Son. If you are like most Fathers, you
                      worry that you spend too much time just trying to get
                      through the day and not enough time creating experiences
                      that help your Son thrive, both today and in the future.
                    </p>
                    <p className="margin-top-10 ">
                      That is where the Dad Son Book comes in for you. This book
                      gives plenty answers to life’s many questions and provides
                      ways to help you initiate your Son into this life so that
                      he doesn’t naïvely face it. It is not just 200 Life
                      Lessons Every Father Must Teach His Son, but also 200 ways
                      for a Father to make his Son wiser and more responsible in
                      life.
                    </p>
                    <div className="margin-top-10 center bold light-yellow">
                      265 Pages -|- Paperback ISBN: 978-0-6397-3979-3
                    </div>
                    <p className="margin-top-10 center boldfont">
                      <q className="white italic">
                        The Dad Son book is a 'Trove of Invaluable Wisdom' that
                        a father should equip his Son with.
                      </q>{" "}
                      <span className="center white ">
                        - Bruno Mukisa Writer, Architect and Poet
                      </span>
                    </p>
                    <p className="margin-top-10">
                      Happy Reading! Happy Fathering!
                    </p>
                    <div className="cta-container section-spacing-20">
                      <button
                        onClick={() => setShow(true)}
                        className="book-cta button light-yellow-button bold uppercase"
                      >
                        ORDER YOUR COPY
                      </button>
                      <Modal onClose={() => setShow(false)} show={show}>
                        <BuyModalContents />
                      </Modal>
                    </div>
                  </div>
                  <div className="about-image-side">
                    <img src={cover2} alt="Dad Son Book" className="cover2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutBook;
