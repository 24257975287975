import React from "react";
import { Helmet } from "react-helmet";
import BuyPremiumSonBundleBody from "../components/buy/BuyPremiumSonBundleBody";

const BuyPremiumSonBundle = () => {
  return (
    <>
      <Helmet>
        <link
          rel="conanical"
          href="https://www.DadSonBook.com/buy-premium-son-bundle"
        />
        <title>Buy Premium Son Bundle of MALIDAD.COM</title>
      </Helmet>
      <BuyPremiumSonBundleBody />
    </>
  );
};

export default BuyPremiumSonBundle;
