import React from "react";
import CheckOutForm from "./CheckOutForm";

const BuyModalContents = () => {
  return (
    <div className="modal-contents threads-bg">
      <div className="modal-head center">
        <h3 className="modal-title uppercase">Buy dad son book:</h3>
        <p className=" margin-top-10 dark">
          Ordering 50+ copies? Click{" "}
          <a
            href="https://form.jotform.com/booksmalidadcom/bulk-order-form-dad-son-book"
            target="_blank"
            rel="noopener noreferrer"
          >
            Here
          </a>
        </p>
      </div>
      <div className="modal-body left section-spacing-40">
        <CheckOutForm />
      </div>
    </div>
  );
};

export default BuyModalContents;
