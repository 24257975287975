import React from "react";
import { Slide } from "react-reveal";

const HowToBuyBody = () => {
  return (
    <section className="section-spacing-100">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <Slide top>
              <div className="section-head center margin-auto">
                <h3 className="modal-title uppercase">How To Order</h3>
                <p className=" margin-top-10 dark">A step by step guide</p>
              </div>
            </Slide>
            <div className="section-body section-spacing-50 no-padding-bottom">
              <div className="order-container width-600 margin-auto padding-8 border-radius-2 box-shadow">
                <p>
                  This article is a "how to guide" on making an order for any of
                  MALIDAD.COM's books of
                  <a
                    href="https://daddaughterbook.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Dad Daughter{" "}
                  </a>
                  ,
                  <a
                    href="https://DadSonBook.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Dad Son{" "}
                  </a>
                  and
                  <a
                    href="https://TheLifetimeTreasure.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    The Lifetime Treasure{" "}
                  </a>
                  books.
                </p>
                <p>Here are the steps to follow explained;</p>
                <h2>■Step 1. Visit book's website</h2>
                <p>
                  Visit the website of any of our book you want to order a copy
                  or copies of i.e.
                </p>
                <p>
                  <a
                    href="https://DadDaughterBook.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    DadDaughterBook.com{" "}
                  </a>
                  (Dad Daughter Book),
                  <a
                    href="https://DadSonBook.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    DadSonBook.com{" "}
                  </a>
                  (Dad Son Book),
                  <a
                    href="https://TheLifetimeTreasureBook.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    TheLifetimeTreasureBook.com{" "}
                  </a>
                  (The Lifetime Treasure book).
                </p>
                <p>
                  Once it has loaded, tap on "<strong>ORDER YOUR COPY</strong>"
                  button.
                </p>
                <div>
                  <img
                    src="https://res.cloudinary.com/malidad/image/upload/v1678947562/order_m5bb2n.jpg"
                    alt="DadDaughterBook.com homepage screenshot"
                  />
                  <p>DadDaughterBook.com homepage screenshot</p>
                </div>
                <h2>■■Step 2. Provide your details</h2>
                <p>
                  When you tap on the "<strong>ORDER YOUR COPY</strong>" button
                  from step 1 above, you will be taken to the
                  <a
                    href="https://https://daddaughterbook.com/buy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    buy page{" "}
                  </a>
                  where you will be required to enter your details.
                </p>
                <p>
                  <strong>Your full name </strong> (e.g. John Doe),
                  <strong> your email address </strong> (e.g.
                  johndoe@email.com),
                  <strong> your phone number </strong> (e.g. +256770123456),
                  <strong> your delivery address </strong>
                  (Delivery address has 3 fields i.e.
                </p>
                <ol>
                  <li>
                    <h3>The first field is 'Street Address'</h3>
                    <p>
                      Street Address (This is your exact location or the
                      location where you want your book delivered. For example;
                      William Street, Kalungi Plaza, Level 5 Room 4)
                    </p>
                  </li>
                  <li>
                    <h3>The second field is 'City/District'</h3>
                    <p>
                      City/District ( This is the district/city where your
                      street address is located. For the above example; Kampala)
                    </p>
                  </li>
                  <li>
                    <h3>The third field is 'Country'</h3>
                    <p>
                      Country ( You will just select your country from the
                      countries list. The city and district you entered above
                      must align with the country you select. You can't enter
                      your city as Kampala and choose Kenya as your country.
                      That is misleading to the delivery person) ),
                    </p>
                  </li>
                </ol>
                <p>
                  <strong>Number of copies</strong> (e.g. 10. This field takes
                  only numbers and this field contributes to the total amount
                  you will pay. The default number of copies is 1)
                </p>
                <p>
                  <strong>Are you Gifting?</strong> (Check Yes if you are
                  gifting the copy of the book to your son, daughter, friend or
                  wife on their birthdays or as tokens of appreciation and check
                  No if you are buying a copy for yourself)
                </p>
                <p>
                  <strong>Additional Information</strong> ( In this field, you
                  can tell us any other information you want us to know with
                  regard to your order and delivery. You could tell us to either
                  first give you a call as the delivery man setsoff or hint us
                  on the time you will be at your delivery address, or any extra
                  message you want us to add on your copy and packaging)
                </p>
                <p>
                  If you have filled in the fields, cross-check and confirm if
                  all the information you entered is correct. Then tap on "PAY
                  NOW" button
                </p>
                <h2>■■■Step 3. Enter Payment Details</h2>
                <p>
                  When you tap on "<strong>PAY NOW</strong>" button from step 2,
                  you will be taken to a page where you will be prompted to
                  enter your payment details. By default, it is mobile money.
                </p>
                <p>Though there are two ways to pay;</p>
                <h3>1. Paying using Mobile Money</h3>
                <p>
                  To pay using mobile money, you will enter your mobile money
                  number if different from the one you provided in step 2.
                </p>
                <div>
                  <img
                    src="https://res.cloudinary.com/malidad/image/upload/v1678947562/momo-number_cjkrcc.jpg"
                    alt="Enter mobile money number screenshot from daddaughterbook.com"
                  />
                  <p>
                    Enter mobile money number screenshot from
                    daddaughterbook.com
                  </p>
                </div>
                <p>And then tap on the pay button.</p>
                <p>
                  Flutterwave will send you a "<strong>PaymentOTP</strong>" via
                  sms or WhatsApp (if the number you entered is on WhatsApp)
                </p>
                <div>
                  <img
                    src="https://res.cloudinary.com/malidad/image/upload/v1678947561/proceedToOTP_gucwhp.jpg"
                    alt="Proceed screenshot from daddaughterbook.com"
                  />
                  <p>Proceed screenshot from daddaughterbook.com</p>
                </div>
                <p>
                  Copy or note down that "<strong>PaymentOTP</strong>" (Usually
                  6 digits) somewhere and then tap on "<strong>PROCEED</strong>"
                  button as in the above screenshot.
                </p>
                <p>
                  In the screen that will show up, you will be required to enter
                  the "<strong>PaymentOTP</strong>" that you copied and tap on "
                  <strong>Validate OTP</strong>" button.
                </p>
                <div>
                  <img
                    src="https://res.cloudinary.com/malidad/image/upload/v1678947562/OTPverify_rfaggo.jpg"
                    alt="Verify PaymentOTP screenshot from daddaughterbook.com"
                  />
                  <p>Verify PaymentOTP screenshot from daddaughterbook.com</p>
                </div>
                <p>
                  If you have enough balance on your mobile money account, a
                  prompt will appear from Mobile Money requiring you to enter
                  pin to complete the transaction.
                </p>
                <p>
                  Then we shall receive a notification of your order and at the
                  same time a receipt of your payment will be sent to your email
                  address.
                </p>
                <p>
                  And we will work on your delivery. It is also important to
                  remember that the amount you pay does not include delivery
                  fees and we deliver worldwide made possible of our shipping
                  partners.
                </p>
                <h3>2. Paying Using VISA OR MASTERCARD</h3>
                <p>
                  When you tap on "PAY NOW" button from step 2, you will be
                  taken to a page where you will be prompted to enter your
                  payment details.
                </p>
                <p>
                  Ignore that and look at the bottom of the page you have at
                  display. Tap on "<strong>Change payment method</strong>"
                </p>
                <div>
                  <img
                    src="https://res.cloudinary.com/malidad/image/upload/v1678947562/changePayment_vopv9w.jpg"
                    alt="Change payment method screenshot from daddaughterbook.com"
                  />
                  <p>
                    Change payment method screenshot from daddaughterbook.com
                  </p>
                </div>
                <p>You will be asked on How would you like to pay?</p>
                <div>
                  <img
                    src="https://res.cloudinary.com/malidad/image/upload/v1678947561/cardPayment_nehuiw.jpg"
                    alt="Select Card screenshot from daddaughterbook.com"
                  />
                  <p>Select Card screenshot from daddaughterbook.com</p>
                </div>
                <p>
                  On the options given, select "<strong>CARD</strong>"
                </p>
                <p>
                  You will then be shown a screen to enter your card details.
                  <strong>CARD NUMBER</strong>, <strong>Expiry Date </strong>{" "}
                  and
                  <strong> CVV</strong> (the 3 digits behind your card)
                </p>
                <div>
                  <img
                    src="https://res.cloudinary.com/malidad/image/upload/v1678947562/cardDetails_v1jqme.jpg"
                    alt="Enter card details screenshot from daddaughterbook.com"
                  />
                  <p>Enter card details screenshot from daddaughterbook.com</p>
                </div>
                <p>Then tap on Pay button.</p>
                <p>
                  If you have enough balance on your account that the card links
                  to, the payment transcation will be successful and completed.
                </p>
                <p>
                  Then we shall receive a notification of your order and a
                  receipt of your payment will be sent to your email address.
                  And we will work on your delivery. It is also important to
                  remember that the amount you pay does not include delivery
                  fees.
                </p>
                <h2>NB:</h2>
                <ul>
                  <li>
                    It is important to note that we make world wide deliveries.
                  </li>
                  <li>
                    The payment you make is only for the book copy/copies and
                    does not include delivery fees.
                  </li>
                  <li>
                    If you don't want to make your order online, you can give us
                    a call on our numbers{" "}
                    <a href="tel:+256700741111"> +256 (700) 741-111</a>,
                    <a href="tel:+13152742122"> +1 (315) 274-2122 </a>,
                    <a href="tel:+256782167879"> +256 (782) 167-879 </a> or
                    <a href="tel:+256702167879"> +256 (702) 167-879 </a> and we
                    will guide you and how to pay for your order.
                  </li>
                </ul>
                <p>
                  Tap <a href="/"> here to go back to homepage </a> and order
                  your copy. Thank you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowToBuyBody;
