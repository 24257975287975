import React from "react";
import Faq from "../reusables/Faq";
import { Slide } from "react-reveal";

const Faqs = () => {
  return (
    <section className=" section-spacing-80" id="faq">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <Slide top>
              <div className="section-head center width-300 margin-auto">
                <h3 className="section-title title">
                  Frequently asked questions
                </h3>
              </div>
            </Slide>
            <div className="section-body section-spacing-50 no-padding-bottom">
              <ul className="faqs-container">
                <Faq faq="Do you ship internationally?">
                  <p>Yes, we do.</p>
                  <p className="margin-top-10">
                    Wherever you are, we can get your copy delivered. Thanks to
                    our partners. All you have to do is provide your perfect and
                    correct delivery address when making your order.
                  </p>
                </Faq>

                <Faq faq="How protected is this website as I am reluctant to use my bank and mobile money details to pay?">
                  <p className="margin-top-10">
                    Our website is really protected with current SSL
                    certificates as shown by the https in the search bar.
                  </p>
                  <p className="margin-top-10">
                    For payment processing, we do not handle it by ourselves, it
                    is handled by{" "}
                    <a
                      href="https://flutterwave.com/us/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      flutterwave
                    </a>
                    , a payment gateway that is trusted by millions of
                    businesses around the world including Uber, Microsoft, MTN,
                    Chippercash and more.
                  </p>
                </Faq>
                <Faq faq="Are there ebooks for Dad Son book?">
                  <p>Not yet.</p>
                  <p className="margin-top-10">
                    We are keeping it paper so that to help you spark more
                    talking points with your son in a digital infested world
                    today. And make it more easier to gift. What a lifetime gift
                    to your son or male friend this would be?
                  </p>
                </Faq>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faqs;
