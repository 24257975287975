import React, { useState } from "react";
import BuyModalContents from "./BuyModalContents";
import Modal from "./Modal";

const BuyResponsive = () => {
  const [show, setShow] = useState(false);
  return (
    <div className="cta-button-container">
      <button
        onClick={() => setShow(true)}
        className="site-main-cta button light-yellow-button dark uppercase bold"
      >
        Order your copy
      </button>
      <Modal onClose={() => setShow(false)} show={show}>
        <BuyModalContents />
      </Modal>
    </div>
  );
};

export default BuyResponsive;
