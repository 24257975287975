import React from "react";
import { Helmet } from "react-helmet";
import HowToBuyBody from "../components/howToBuy/HowToBuyBody";

const HowToOrder = () => {
  return (
    <>
      <Helmet>
        <link
          rel="conanical"
          href="https://www.DadDaughterBook.com/how-to-order-dad-daughter-book-online"
        />
        <title>How To Order Dad Son Book Copy Online?</title>
      </Helmet>
      <HowToBuyBody />
    </>
  );
};

export default HowToOrder;
