import React from "react";
import { Helmet } from "react-helmet";
import BuyPremiumDaughterBundleBody from "../components/buy/BuyPremiumDaughterBundleBody";

const BuyPremiumDaughterBundle = () => {
  return (
    <>
      <Helmet>
        <link
          rel="conanical"
          href="https://www.DadSonBook.com/buy-premium-daughter-bundle"
        />
        <title>Buy Premium Daughter Bundle of MALIDAD.COM</title>
      </Helmet>
      <BuyPremiumDaughterBundleBody />
    </>
  );
};

export default BuyPremiumDaughterBundle;
